.accordion {
  margin-top: 5rem;

  .accordion__label {
    display: none;
    margin-bottom: 5rem;
    color: $c-tertiary2;
    font-family: 'Intel Bold', Helvetica, sans-serif;
  }
    
  .accordion__item {
    margin-top: -4rem;
    padding: 1rem 2rem 5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: $c-secondary4;
  }

  .accordion__item:nth-child(3n) {
    background-color: $c-secondary3;
  }

  .accordion__item:nth-child(4n) {
    background-color: $c-secondary2;
  }

  .accordion__item:nth-child(5n) {
    background-color: $c-secondary1;
    padding: 1rem 2rem 2rem;
    border-radius: 1rem;
  }

  .accordion__header {
    display: flex;

    .accordion__image {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 0.5rem;
      margin: 0.75rem 1rem 0 0;
      opacity: 0.9;
    }

    h3 {
      margin-top: 0.3rem;
    }

    .accordion__company {
      margin-top: -0.75rem;
      font-size: $f-normal;
      font-family: 'Intel Medium';
    }

    .accordion__time {
      margin-top: -0.25rem;
      font-size: $f-smaller;
    }
  }
  
  .accordion__body {
    display: none;
  }

  .accordion__link {
    display: flex;
    justify-content: center;
  }
  

  @include media($b-medium) {
    margin-top: -3rem;

    .accordion__label {
      display: block;
    }
  }
}