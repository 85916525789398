.availability {
  .availability__alert {
    position: relative;
    padding-left: 1.75rem;
    font-family:  'Intel Bold', Helvetica, sans-serif;

    &.availability__alert::before {
      @include pseudo-base;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 1rem;
      top: 0.6rem;
      left: 0.25rem;
      opacity: 0.8;
      background: $c-secondary1;
    }

    &.availability__alert::after {
      @include pseudo-base;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 1rem;
      top: 0.35rem;
      opacity: 0.5;
      filter: blur(2px);
      background: $c-secondary1;
      animation: fade-in-out-smooth cubic-bezier(0.6, -0.28, 0.74, 0.05) 2.5s infinite;
    }
    
    &.availability--open::before,
    &.availability--open::after {
      background: $c-hightlight2;
    }

    &.availability--closed::before,
    &.availability--closed::after {
      background: $c-hightlight3;
    } 
  }

  &.availability--homepage {
    >.availability__alert {
      color: $c-white;
      font-family: 'Intel Medium';
      padding: 0.3rem 0.6rem 0.3rem 2rem;
      background: rgba($c-primary2, 0.8);
      border: 0.5px solid $c-primary2;
      border-radius: 2rem;

      span {
        font-family: 'Intel Bold';
      }

      img {
        margin: 0 0 0.1rem 0.5rem;
        transform: rotate(-90deg);
      }

      &.availability--open::before,
      &.availability--closed::before {
        top: 0.6rem;
        left: 0.6rem;
      }

      &.availability--open::after,
      &.availability--closed::after {
        top: 0.37rem;
        left: 0.37rem;
      }
    }
  }
  
  .availability__cta {
    margin-top: 3rem;
  }

  @include media($b-small) {
    &.availability--homepage {
      padding: 3rem 0 1rem;
      span { display: none; }
    }
  }

  @include media(345px) {
    &.availability--homepage {
      display: none;
    }
  }
}

.intro {
  .availability > .availability__alert {
    display: none;
  }
}
