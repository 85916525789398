.subscription-form {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem 0 4rem 1.75rem;

  .subscription-form__input {
    flex: 3;
    min-width: 15rem;
    padding: 0.9rem 1rem;
    border: 1px solid $c-white;
    border-radius: 0.5rem;
  }

  .subscription-form__status {
    width: 100%;
    min-height: 2rem;
    margin-bottom: 0;
    color: $c-hightlight3;
  }

  .subscription-form__status--success {
    color: $c-hightlight2;
  }

  @include media($b-medium) {
    margin: 1rem 0 2rem 1.75rem;
  }

  @include media($b-small) {
    width: calc(100% - 2rem);
    margin: 1rem 0 0 1.75rem;
  }
}
