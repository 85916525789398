// Definitions
@import 'styleguide/reset.scss';
@import 'styleguide/breakpoints.scss';
@import 'styleguide/colors.scss';
@import 'styleguide/typography.scss';
@import 'styleguide/mixins.scss';
@import 'styleguide/keyframes.scss';
@import 'styleguide/variables.scss';
@import 'styleguide/grid.scss';

// Components
@import 'components/accordion.scss';
@import 'components/availability.scss';
@import 'components/benefits.scss';
@import 'components/buttons.scss';
@import 'components/contact.scss';
@import 'components/context.scss';
@import 'components/experience.scss';
@import 'components/expertise.scss';
@import 'components/form.scss';
@import 'components/header.scss';
@import 'components/highlights.scss';
@import 'components/intro.scss';
@import 'components/logo-overview.scss';
@import 'components/menu.scss';
@import 'components/mouse.scss';
@import 'components/navigation.scss';
@import 'components/services.scss';
@import 'components/socials.scss';
@import 'components/tags.scss';
@import 'components/testimonials.scss';
@import 'components/tiles.scss';
@import 'components/timeline.scss';
@import 'components/transformation.scss';
@import 'components/video.scss';
@import 'components/work.scss';

// Pages
@import 'pages/homepage.scss';
@import 'pages/productdevelopment.scss';
@import 'pages/welcome.scss';
